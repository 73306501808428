// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/input.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/input.tsx");
  import.meta.hot.lastModified = "1729813714031.6714";
}
// REMIX HMR END

export default function Input({
  title,
  name,
  type = "text",
  placeholder = "",
  maxLength = 1000,
  value,
  setValue,
  error
}) {
  return <div className="input">
      <label htmlFor={name}>{title}</label>
      <input name={name} id={name} type={type} maxLength={maxLength} placeholder={placeholder} onChange={e => {
      setValue(e.target.value);
    }} value={value} />
      {error ? <p className="error">{error}</p> : null}
    </div>;
}
_c = Input;
var _c;
$RefreshReg$(_c, "Input");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;